import DemoFormSection from "components/common/DemoFormSection";
import Features from "components/common/Features";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import InfoSlider from "components/common/InfoSlider";
import GrantsFormSection from "components/grants/GrantsFormSection";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";

import IndustryFeaturedUpdated from "../../components/industries/updated/IndustryFeaturedUpdated";

export default function IntelligentCampusSecurity() {
  const [school, setSchool] = useState(null);

  const cleanUrlParam = inputParam => {
    const pattern = /[^a-zA-Z0-9 ]/g;
    const result = inputParam.replace(pattern, " ");
    return result;
  };

  useEffect(() => {
    if (!window || window === undefined) return;
    const searchParams = new URLSearchParams(window.location.search);
    const schoolParam = searchParams.get("district_name");
    if (!schoolParam) return;
    setSchool(cleanUrlParam(schoolParam));
  }, []);

  const dynamicTitle = useCallback(() => {
    if (!school) return "Welcome to Rhombus";
    return `${school}, Welcome to Rhombus`;
  }, [school]);

  const renderContent = data => {
    const headerSection = {
      preTitle: "rhombus for education",
      title: dynamicTitle(),
      description:
        "Discover your all-in-one solution for elevating the safety and security of students and faculty. With Rhombus schools can expand visibility, combat student vaping, control building access, and accelerate emergency response. ",
      image: data.headerImage,
      button1: { text: "Request Demo", path: null },
    };

    const logos = [
      {
        img: data.logo1,
        width: "89px",
        alt: "Clark Atlanta Univeristy",
      },
      {
        img: data.logo2,
        width: "118px",
        alt: "Jesuit",
      },
      {
        img: data.logo3,
        width: "135px",
        alt: "Mississippi Department of Education",
      },
      {
        img: data.logo4,
        width: "99px",
        alt: "Pacific University Oregon",
      },
      {
        img: data.logo5,
        width: "174px",
        alt: "Conestoga Valley",
      },
      {
        img: data.logo6,
        width: "158px",
        alt: "Los Angeles Film School",
      },
    ];

    const infoSlides = [
      {
        tabTitle: "User-Friendly",
        title: "Keep Students and Faculty Safe with a User-Friendly Platform ",
        description:
          "Fortify school security with cloud-based cameras and real-time AI alerts for proactive threat detection anytime, anywhere. Cover blind spots, support School Resource Officers, and streamline investigations with easy-to-use tools.",
        image: data.slide1,
        link: { path: "/cameras/", title: "Learn About Cloud-Based Cameras" },
      },
      {
        tabTitle: "Emergency Response",
        title:
          "Accelerate Emergency Response and Coordinate with First Responders",
        description:
          "Act swiftly in emergencies and access a range of solutions, including person-of-interest detection, panic buttons, and alarm monitoring. Verify threats, reduce false alarms, and relay key details to emergency services efficiently.",
        image: data.slide2,
        link: {
          path: "/alarm-monitoring/",
          title: "Learn About Alarm Monitoring",
        },
      },
      {
        tabTitle: "Detect Vaping",
        title: "Monitor Air Quality and Detect Student Vaping",
        description:
          "Maintain a healthy school environment and reduce student vaping with air quality and vape/smoke/THC smart sensors. Automatically detect e-cigarette and tobacco use in real time and obtain actionable evidence while preserving student privacy. ",
        image: data.slide3,
        link: { path: "/sensors/", title: "Learn About IoT Sensors" },
      },
      {
        tabTitle: "Building Access",
        title: "Control Campus Perimeters and Building Access",
        description:
          "Centrally manage unlimited doors, buildings, and credentials from anywhere. Detect unauthorized visitors and quickly understand who’s on school property by pairing access events with synchronized video, badge data, and time stamps.",
        image: data.slide4,
        link: { path: "/access-control/", title: "Learn About Access Control" },
      },
      {
        tabTitle: "Compliance",
        title: "Meet State Compliance",
        description:
          "Stay up to date with school security regulations at the state and federal levels. With modern infrastructure, a highly scalable system, and automatic updates at no cost, it’s easy to maintain compliance as security needs evolve today and in the future. ",
        image: data.slide5,
        link: { path: "/trust/", title: "Learn About Compliance" },
      },
    ];

    const features = [
      {
        icon: data.icon1,
        iconAlt: "Protect Students and Staff",
        title: "Protect Students and Staff",
        p:
          "Increase campus-wide visibility with high-resolution video and AI analytics to quickly identify, deter, and resolve unwanted events",
      },
      {
        icon: data.icon2,
        iconAlt: "Swiftly Respond to Incidents",
        title: "Swiftly Respond to Incidents",
        p:
          "Receive proactive real-time alerts for unusual behavior and share live footage with emergency services from the Rhombus Mobile App.",
      },
      {
        icon: data.icon3,
        iconAlt: "Stay Ahead of Threats",
        title: "Stay Ahead of Threats",
        p:
          "Utilize industry-leading AI analytics across all devices to receive proactive alerts for unauthorized people, smoking or vaping, and flagged vehicles.",
      },
      {
        icon: data.icon4,
        iconAlt: "Simplify Management",
        title: "Simplify Management",
        p:
          "No DVR/NVRs or complex configurations required. Cameras come online in minutes, include native remote access, and update automatically.",
      },
    ];

    const formSection = {
      preTitle: "REQUEST DEMO",
      title: "Interested in Rhombus?",
      description:
        "Get a demo today and see how Rhombus can transform your physical security.",
      learnLines: [
        "Why a cloud-native solution is superior to on-premise video security systems",
        "The benefits of using a unified platform with smart cameras, sensors, and integrations",
        "How to improve safety and productivity with powerful AI analytics",
      ],
      id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
    };

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Rhombus Helps Schools Find and Apply for School Safety Grant Funds
          </title>
          <meta
            name="description"
            content="Rhombus helps connect schools with grants to increase physical security and access control for schools."
          />
        </Helmet>
        <HeroLayout1 data={headerSection} />
        <IndustryFeaturedUpdated
          title="Trusted by Education Leaders"
          logos={logos}
        />
        <GrantsFormSection />
        <InfoSlider
          data={infoSlides}
          title="Create Safer Schools"
          color="var(--nuetral-100)"
        />
        <Features
          data={features}
          title="Rhombus Platform Features"
          color="var(--white)"
        />
        <DemoFormSection data={formSection} color="var(--gradient-light)" />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/education/education-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      logo1: file(
        relativePath: { eq: "components/industries/img/education/logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/education/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/education/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/education/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/industries/img/education/logo-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/education/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      slide1: file(
        relativePath: {
          eq: "components/webinar/updated/img/user-friendly-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide2: file(
        relativePath: {
          eq: "components/webinar/updated/img/proactive-responses-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide3: file(
        relativePath: {
          eq: "components/webinar/updated/img/prevent-vaping-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide4: file(
        relativePath: { eq: "components/webinar/updated/img/monitor-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide5: file(
        relativePath: {
          eq: "components/industries/updated/img/education/smart-sensors-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/shield.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
